import styled from '@emotion/styled'

export const FooterContainer = styled.footer`
  background-color: #303030;
  color: silver;
  font-family: ${props => props.theme.fontFamily};
  width: 100%;
  margin: 5rem auto 0;
  padding: 2rem 2rem 1.5rem;
  font-size: 16px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 14px;
  }
  .container {
    max-width: ${props => props.theme.maxWidth};
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      justify-content: center;
    }
    > div {
      p {
        letter-spacing: 1px;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          text-align: center;
        }
      }
      a, a:visited, a:focus {
        color: silver;
        text-decoration: none;
        transition: color 0.2s ease-out;
      }
      a:hover {
        color: white;
      }
      .footer-social {
        margin: 0; 
        padding: 0;
        li {
          font-size: 19px;
          list-style-type: none;
          display: inline-block;
          margin-left: 20px;
        }
      }
    }
  }
`