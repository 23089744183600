const theme = {
  colors: {
    background: '#ffffff',
    text: '#000000',
    buttons: `#ec5d57`,
    ctaText: `#ec5d57`,
    buttonsBackground: '#0080ff',
    buttonsBackgroundHover: '#0874e0',
    buttonsDisabled: '#b5bdc5',
    buttonsShadow: '0 4px 12px rgba(0,128,255,0.15)',
    links: '#0080ff',
  },
  fontFamily: "nunito, sans-serif",
  maxWidth: '1170px',
  breakpoints: {
    xxs: '320px',
    xs: '420px',
    xsLandscape: '668px',
    s: '1050px',
    m: '1248px',
    l: '1366px',
  },
}

export default theme
